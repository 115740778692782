import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import ClearIcon from '@material-ui/icons/Clear';
import { Grid } from '@material-ui/core';

import Hapour from '../lib/Hapour';

const styles = theme => ({
  textField: {
    marginTop: '6px',
  },
});

class PlaceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editHours: false,
      errors: {},
      dowChecked: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      timeRange: {
        time_start: '',
        time_end: '',
      },
    };
  }

  toggleEditHours = () => {
    if (this.state.editHours) {
      this.setState({
        dowChecked: {
          0: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
        },
        timeRange: {
          time_start: '',
          time_end: '',
        },
      });
    }
    this.setState({
      editHours: !this.state.editHours,
    });
  }

  getLatLong = () => {
    let address = this.props.place.address;
    const gMapsGeoCodeRequestUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDvp_xzECeSt2xVq79TCUWpreM6N5nygwc`;
    let lat, lng;
    if (!address) {
      this.props.onChange({target: {name: 'lat', value: 0 }});
      this.props.onChange({target: {name: 'lng', value: 0 }});
      return { lat: 0, lng: 0 };
    } else {
      fetch(gMapsGeoCodeRequestUrl)
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        response.json().then((data) => {
          let result = data.results[0];
          console.log(result);
          if (!!result && !!result.geometry && !!result.geometry.location) {
            lat = result.geometry.location.lat;
            lng = result.geometry.location.lng;
          }
          this.props.onChange({target: {name: 'lat', value: lat }});
          this.props.onChange({target: {name: 'lng', value: lng }});
          return { lat, lng };
        });
      })
      .catch(function(err) {
        console.log('Fetch Error :-S', err);
      });
    }
  }

  handleTimeUpdate = (event) => {
    const timeRange = Object.assign({}, this.state.timeRange, {
      [event.target.name]: event.target.value,
    });
    this.setState({ timeRange });
  };

  handleToggleDaily = () => {
    const newValue = !Object.values(this.state.dowChecked).every(dow => dow);
    const dowChecked = {
      0: newValue,
      1: newValue,
      2: newValue,
      3: newValue,
      4: newValue,
      5: newValue,
      6: newValue,
    };
    this.setState({ dowChecked });
  }

  handleToggle = (event, value) => {
    this.state.dowChecked[value] = !this.state.dowChecked[value];
    this.forceUpdate();
  }
  
  render() {
    const { classes, onChange, place } = this.props;
    const { errors } = this.state;

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const daysOfWeekCheckboxes = [];
    // MAKE FOR LOOP TO USE BOTH # and labels, pass #s and store these in db instead of dow strings.
    for (let i = 0; i < daysOfWeek.length; i += 1) {
      daysOfWeekCheckboxes.push(
        <ListItem dense button key={i}>
          <ListItemText
            primary={daysOfWeek[i]}
            onClick={event => this.handleToggle(event, i)}
          />
          <ListItemSecondaryAction>
            <Checkbox
              onClick={event => this.handleToggle(event, i)}
              checked={this.state.dowChecked[i]}
              color="primary"
            />
          </ListItemSecondaryAction>
        </ListItem>,
      );
    }

    return (
      <div>
        <Card style={{ textAlign: 'left' }}>
          <div style={{ margin: '8px 10px' }}>
            <form action="/" onSubmit={this.props.onSave}>
              {errors.summary && <p className="error-message">{errors.summary}</p>}
              <div style={{ margin: '0 auto' }}>
                <div className="fieldLine">
                  <TextField
                    label="Place Name"
                    name="name"
                    helperText={errors.name}
                    onChange={onChange}
                    value={place.name}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                  />
                </div>
                <div className="fieldLine">
                  <TextField
                    label="Address"
                    name="address"
                    onChange={onChange}
                    onBlur={this.getLatLong}
                    helperText={errors.address}
                    value={place.address}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                  />
                  <span style={{ fontSize: '10px', color: 'gray', top: '-8px', position: 'relative' }}>{`(${place.lat}, ${place.lng})`}</span>
                </div>
                <div className="fieldLine">
                  <TextField
                    label="Food Notes"
                    name="foodNotes"
                    onChange={onChange}
                    helperText={errors.foodNotes}
                    value={place.foodNotes}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                  />
                </div>
                <div className="fieldLine">
                  <TextField
                    label="Drinks Notes"
                    name="drinksNotes"
                    onChange={onChange}
                    helperText={errors.drinksNotes}
                    value={place.drinksNotes}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                  />
                </div>
                <div className="fieldLine">
                  <TextField
                    label="Notes"
                    name="otherNotes"
                    onChange={onChange}
                    helperText={errors.otherNotes}
                    value={place.otherNotes}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                  />
                </div>
                <div className="fieldLine">
                  <TextField
                    label="Happy Hour Hours"
                    name="hours"
                    helperText={errors.hours}
                    onClick={this.toggleEditHours}
                    value={Hapour.friendlyHours(place.hours) ? Hapour.friendlyHours(place.hours) + '. (tap to add)' : ''}
                    multiline
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Reset Hours"
                            onClick={(e) => { e.stopPropagation(); this.props.resetHours(); }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="fieldLine">
                  <TextField
                    label="Happy Hour Website"
                    name="happyHourUrl"
                    helperText={errors.happyHourUrl}
                    onChange={onChange}
                    value={place.happyHourUrl}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                  />
                </div>
                {/* <div className="fieldLine">
                  <TextField
                    label="Main Website"
                    name="placeUrl"
                    onChange={onChange}
                    helperText={errors.placeUrl}
                    value={place.placeUrl}
                    fullWidth
                    margin="normal"
                  />
                </div> */}
                <div className="fieldLine">
                  <TextField
                    label="Yelp Website"
                    name="yelpUrl"
                    onChange={onChange}
                    helperText={errors.yelpUrl}
                    value={place.yelpUrl}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    className={classes.textField}
                  />
                </div>
                {/* <div className="fieldLine">
                  <TextField
                    label="Place Logo URL"
                    name="avatarUrl"
                    helperText={errors.avatarUrl}
                    onChange={onChange}
                    value={place.avatarUrl}
                    fullWidth
                    margin="normal"
                  />
                </div> */}
                <div style={{ marginTop: '12px' }}>
                  <Grid justify="space-between" container>
                    <Grid item>
                      <Button variant="outlined" onClick={this.props.onCancel}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" type="submit" color="primary">
                        {this.props.place.key.length === 0 ? 'Add Place' : 'Save Place Details'}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
                <br />
                <Dialog
                  title="Add Hours"
                  open={this.state.editHours}
                  onClose={this.toggleEditHours}
                >
                  <DialogContent>
                    <List>
                      <ListItem dense button key="daily">
                        <ListItemText
                          primary="Daily"
                          onClick={this.handleToggleDaily}
                        />
                        <ListItemSecondaryAction>
                          <Checkbox
                            onClick={this.handleToggleDaily}
                            checked={Object.values(this.state.dowChecked).every(dow => dow)}
                            color="primary"
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {daysOfWeekCheckboxes}
                    </List>
                    <div style={{ textAlign: 'center' }}>
                      <TextField
                        id="time"
                        name="time_start"
                        label="Start"
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 900, // 15 min
                        }}
                        value={this.state.timeRange.time_start}
                        onChange={this.handleTimeUpdate}
                      />
                      <br />
                      <br />
                      <TextField
                        id="time"
                        name="time_end"
                        label="End"
                        type="time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 900, // 15 min
                        }}
                        value={this.state.timeRange.time_end}
                        onChange={this.handleTimeUpdate}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                      onClick={this.toggleEditHours}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => { this.toggleEditHours(); this.props.addHours(this.state.dowChecked, this.state.timeRange); }}
                    >
                      Add
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </form>
          </div>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(PlaceForm);
