import React, { Component } from 'react';

import * as firebase from 'firebase';
import { GeoFire } from 'geofire';

import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';

import PlaceCard from '../components/PlaceCard';
import PlaceForm from '../components/PlaceForm';

class PlacePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      placeKey: this.props.match.params.place_key ? decodeURIComponent(this.props.match.params.place_key) : '',
      editMode: this.props.match.params.place_key === undefined || this.props.match.params.place_key.length === 0,
      place: {
        key: '',
        name: '',
        avatarUrl: '',
        happyHourUrl: '',
        placeUrl: '',
        yelpUrl: '',
        address: '',
        hours: [[], [], [], [], [], [], []],
        foodNotes: '',
        drinksNotes: '',
        otherNotes: '',
        lat: 0,
        lng: 0,
      },
    };
  }

  loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        user,
      });
    });

    this.loadPlaceDetails(this.state.placeKey);
  }

  loadPlaceDetails = (placeKey) => {
    if (this.state.placeKey.length > 0) {
      const placesRef = firebase.database().ref(`/places/${placeKey}`);
      let exists = false;
      placesRef.on('value', (snapshot) => {
        exists = snapshot.hasChildren();
        if (exists) {
          let place = {
            key: placeKey,
          };
          place = Object.assign(place, snapshot.val());
          if (place.placePictures) {
            place.placePictures.forEach((pic, i) => { pic.i = i; });
            place.placePictures = place.placePictures.filter(pic => !pic.isArchived);
            place.placePictures.reverse();
          }
          this.setState({
            place,
          });
          // Add to /recents
          let mru = JSON.parse(localStorage.getItem('mru'));
          if (!mru) {
            mru = [];
          }
          if (mru.indexOf(placeKey) > -1) {
            mru.splice(mru.indexOf(placeKey), 1);
          }
          mru.push(placeKey);
          if (mru.length > 10) {
            mru.shift();
          }
          localStorage.setItem('mru', JSON.stringify(mru));
        }
      });
    }
  }

  onEditPlace = () => {
    this.setState({
      editMode: true,
    });
  }

  cancelEdit = (e) => {
    if (this.state.placeKey.length === 0) {
      this.props.history.goBack();
    } else {
      this.loadPlaceDetails(this.state.placeKey);
      this.setState({
        editMode: false,
      });
    }
  }

  changeStore = (event) => {
    const field = event.target.name;
    const place = this.state.place;
    if (field === 'hours') {
      this.setState({
        editHours: !this.state.editHours,
      });
    } else if (event.target.type === 'checkbox') {
      place[field] = event.target.checked;
    } else {
      place[field] = event.target.value;
    }
    this.setState({
      place,
    });
  }

  savePlace = (event) => {
    event.preventDefault();
    const placesRef = firebase.database().ref('/places');
    const placesGeoRef = firebase.database().ref('/placesGeo');
    const geoFire = new GeoFire(placesGeoRef);
    if (!!this.state.place.name && this.state.place.name.length > 0) {
      if (!!this.state.place.key && this.state.place.key.length > 0) {
        placesRef.child(this.state.place.key).set(
          this.state.place
        )
        .then(() => { // error handling
          this.setState({
            editMode: false,
          })
        })
        geoFire.set(this.state.place.key, [this.state.place.lat, this.state.place.lng]);
      } else {
        const newPlaceRef = placesRef.push();
        let place = this.state.place;
        place.key = newPlaceRef.key;
        this.setState({
          place
        });
        newPlaceRef.set(
          place
        )
        .then(() => { // error handling
          this.setState({
            editMode: false,
          })
        })
        geoFire.set(newPlaceRef.key, [this.state.place.lat, this.state.place.lng]);
      }
    }
  }

  resetHours = () => {
    this.setState({
      place: Object.assign(
        {},
        this.state.place,
        { hours: [[], [], [], [], [], [], []] },
      ),
    });
  }

  addHours = (dow, timeRange) => {
    const hours = this.state.place.hours ? JSON.parse(JSON.stringify(this.state.place.hours)) : [[], [], [], [], [], [], []];
    Object.entries(dow).forEach(([k, v]) => {
      if (v) {
        if (!hours[k]) {
          hours[k] = [];
        }
        hours[k].push(Object.assign({}, timeRange));
      }
    });
    const place = Object.assign({}, this.state.place, { hours });
    this.setState({ place });
  }

  /**
   * Render the component.
   */
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Grow in>
          {!this.state.editMode || (this.state.editMode && this.state.user) ? (
            <div>
              {this.state.editMode ? (
                <PlaceForm
                  place={this.state.place}
                  onCancel={this.cancelEdit}
                  onChange={this.changeStore}
                  onSave={this.savePlace}
                  addHours={this.addHours}
                  resetHours={this.resetHours}
                />
              ) : (
                <PlaceCard
                  place={this.state.place}
                  showExternalLinks
                  showAddress
                  showMap
                  showPhotos
                  lastUpdated
                  editButton
                  addPicsButton
                  onClickEdit={this.onEditPlace}
                />
              )}
            </div>
          ) : (
            <div style={{ minHeight: '80vh', fontSize: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <div>
                <Button variant="contained" color="primary" onClick={this.loginWithGoogle}>
                  Log in
                </Button>
              </div>
              <div>
                to contribute or save your
                <br />
                favorite happy hour spots.
              </div>
            </div>
          )}
        </Grow>
      </div>
    );
  }

}

export default PlacePage;