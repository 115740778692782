import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';

import ClearIcon from '@material-ui/icons/Clear';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  searchBoxDiv: {
    backgroundColor: theme.palette.primary.main,
    padding: '8px 12px',
  },
  textField: {
    marginTop: '4px',
    marginBottom: '4px',
    width: '100%',
  },
  adornmentIcons: {
    color: 'gray',
  },
  whiteBackground: {
    backgroundColor: 'white',
    borderRadius: '4px',
  },
});

class SearchDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latlng: null,
      location: '',
      query: '',
      expanded: {
        time: false,
        distance: false,
        sort: false,
      },
      selectedDow: false,
      time: false,
    };
  }

  handleClickListItem = (e) => {
    const newExpanded = Object.assign({}, this.state.expanded);
    newExpanded[e] = !this.state.expanded[e];
    this.setState({
      expanded: newExpanded,
    });
  }

  handleChangeDow = (e) => {
    this.setState({
      selectedDow: e.target.value,
    });
  }

  handleChangeTime = (e) => {
    this.setState({
      time: e.target.value,
    });
  }

  handleChange = (e) => {
    if (e.target) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ location: e });
    }
  };

  handleSelect = (location) => {
    geocodeByAddress(location)
      .then(results => getLatLng(results[0]))
      .then((latlng) => {
        this.setState({
          latlng,
        });
      })
      .catch(error => console.error('Error', error));
  };

  handleSearch = () => {
    this.props.onClose();
    const search = [];
    if (this.state.query) {
      search.push(`q=${this.state.query}`);
    }
    if (this.state.latlng) {
      search.push(`l=${this.state.latlng.lat},${this.state.latlng.lng}`);
    }
    if (this.state.selectedDow) {
      search.push(`dow=${this.state.selectedDow}`);
    }
    if (this.state.time) {
      search.push(`t=${this.state.time}`);
    }
    this.props.history.push({
      pathname: '/search',
      search: `?${search.join('&')}`,
    });
    this.props.setBaseState(
      'query',
      {
        latlng: this.state.latlng,
        query: this.state.query,
        dow: this.state.selectedDow,
        time: this.state.time,
      },
    );
  }

  render() {
    const { classes, currentLocation, onClose, open } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
      >
        <DialogTitle className={classes.searchBoxDiv}>
          <TextField
            id="outlined-bare"
            placeholder="Search"
            name="query"
            value={this.state.query}
            onChange={this.handleChange}
            margin="none"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.adornmentIcons} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {this.state.query && (
                    <ClearIcon className={classes.adornmentIcons} onClick={() => this.setState({ query: '' })} />
                  )}
                </InputAdornment>
              ),
              classes: {
                root: classes.whiteBackground,
              },
            }}
            inputProps={{ style: { padding: '12px 0px' } }}
            className={classes.textField}
          />
          <PlacesAutocomplete
            value={this.state.location}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            searchOptions={{
              location: (currentLocation && currentLocation.lat) ? (new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng)) : null,
              radius: 32000, // radius in meters, ~20mi
              // types: ['address'],
            }}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                {/* <input
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'location-search-input',
                  })}
                /> */}
                <TextField
                  {...getInputProps({
                  })}
                  id="outlined-bare"
                  name="location"
                  placeholder="Current Location"
                  margin="none"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationIcon className={classes.adornmentIcons} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        {this.state.location && (
                          <ClearIcon className={classes.adornmentIcons} onClick={() => this.setState({ location: '', latlng: null })} />
                        )}
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.whiteBackground,
                    },
                  }}
                  inputProps={{ style: { padding: '12px 0px' } }}
                  className={classes.textField}
                />
                {(suggestions && suggestions.length > 0) && (
                  <List
                    className="autocompletbee-dropdown-container"
                    style={{ position: 'absolute', backgroundColor: '#fff', zIndex: 10, width: 'calc(100vw - 24px)', padding: 0, marginTop: '8px' }}
                  >
                    {loading && <div style={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '8px' }}><CircularProgress /></div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <ListItem button divider
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <ListItemText primary={suggestion.description} onClick={() => { this.setState({ location: suggestion.description }); }} />
                          {/* <span>{JSON.stringify(suggestion.formattedSuggestion)}</span> */}
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem button divider onClick={() => this.handleClickListItem('time')}>
              <ListItemText primary="Happy hour time" secondary={this.state.queryOpenAt} />
              {(this.state.selectedDow || this.state.time) && (
                <ClearIcon className={classes.adornmentIcons} onClick={() => this.setState({ selectedDow: false, time: false })} />
              )}
            </ListItem>
            <Collapse in={this.state.expanded.time}>
              <ListItem divider style={{ flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'].map((dow, i) => (
                    <Radio
                      checked={this.state.selectedDow === `${i}`}
                      onChange={this.handleChangeDow}
                      value={i}
                      name="dow"
                      icon={dow}
                      checkedIcon={dow}
                      color="primary"
                    />
                  ))}
                </div>
                <br />
                <TextField
                  id="time"
                  name="time"
                  label="Time"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 900, // 15 min
                  }}
                  value={this.state.time}
                  onChange={this.handleChangeTime}
                />
              </ListItem>
            </Collapse>
            <ListItem disabled button divider onClick={() => this.handleClickListItem('distance')}>
              <ListItemText primary="Max distance" secondary={this.state.queryMaxDistance} />
            </ListItem>
            <ListItem disabled button onClick={() => this.handleClickListItem('sort')}>
              <ListItemText primary="Sort by" secondary={this.state.querySort} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleSearch}
          >
            Search
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SearchDialog);
