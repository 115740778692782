import React, { Component } from 'react';
import * as firebase from 'firebase';
import Button from '@material-ui/core/Button';

import PlacesList from '../components/PlacesList';

class RecentsPage extends Component {
  constructor() {
    super();
    this.state = {
      places: new Array(10),
    };
  }

  componentDidMount() {
    let mru = JSON.parse(localStorage.getItem('mru'));
    if (!mru) {
      mru = [];
    }
    mru.reverse();
    for (let i = 0; i < mru.length; i += 1) {
      const placeKey = mru[i];
      const placesRef = firebase.database().ref(`/places/${placeKey}`);
      placesRef.once('value', (snapshot) => {
        const place = {
          key: placeKey,
        };
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          place[childKey] = childData;
        });
        this.state.places[i] = place;
        this.forceUpdate();
      });
    }
  }

  clearRecents = () => {
    localStorage.removeItem('mru');
    this.setState({
      places: new Array(10),
    });
  }

  render() {
    return (
      <div>
        <PlacesList
          places={this.state.places}
        />
        {this.state.places[0] && (
          <div style={{ textAlign: 'center' }}>
            <Button onClick={this.clearRecents}>
              Clear Recents
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default RecentsPage;
