import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as firebase from 'firebase';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Jimp from 'jimp/es';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


import AddPhotosIcon from '@material-ui/icons/AddPhotoAlternate';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import DrinkIcon from '@material-ui/icons/LocalDrinkOutlined';
import EditIcon from '@material-ui/icons/Edit';
import NotesIcon from '@material-ui/icons/Notes';
import PlaceIcon from '@material-ui/icons/PlaceOutlined';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ThumbDownIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUpOutlined';

import Hapour from '../lib/Hapour';

const styles = theme => ({
  chip: {
    marginLeft: '8px',
    borderRadius: '5px',
    height: '22px',
    fontStyle: 'italic',
    backgroundColor: '#dbeefc',
  },
  label: {
    paddingLeft: '2px',
    paddingRight: '6px',
  },
});

const GoogleMapView = withGoogleMap(props => (
  <GoogleMap
    ref={props.onMapLoad}
    defaultZoom={15}
    center={{
      lat: props.place.lat,
      lng: props.place.lng,
    }}
    options={{
      scaleControl: true,
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      minZoom: 9,
      maxZoom: 18,
    }}
  >
    {props.markers.map((marker, index) => (
      <Marker
        key={index}
        position={marker.position}
      />
    ))}
  </GoogleMap>
));

class PlaceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favorited: false,
      favRef: null,
      lightboxIsOpen: false,
      currentImage: 0,
      imageIndex: null,
      isUploading: false,
      uploadProgress: 0,
    };
    this.inputOpenFileRef = React.createRef();
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        user,
      });
    });
  }

  componentDidUpdate() {
    if (this.props.place.key && !this.state.favRef && this.state.user) {
      const userId = firebase.auth().currentUser.uid;
      const favRef = firebase.database().ref(`/users/${userId}/favorites/${this.props.place.key}`);
      this.setState({
        favRef: favRef,
      });
      favRef.on('value', (snapshot) => {
        this.setState({
          favorited: snapshot.hasChild('dateAdded'),
        });
      });
    }
  }

  componentWillUnmount() {
    if (this.state.favRef) {
      this.state.favRef.off();
    }
  }

  toggleFavaorite = () => {
    if (this.state.user) {
      const newState = !this.state.favorited;
      this.setState({
        favorited: newState,
      });
      if (newState) {
        this.state.favRef.set({
          dateAdded: new Date().getTime()
        });
      } else {
        this.state.favRef.remove();
      }
    } else {
      this.props.history.push('/favorites');
    }
  }

  goToPlacePage = (e) => {
    if (!(['svg', 'button', 'path'].includes(e.target.tagName.toLowerCase()))) {
      this.props.history.push(`/place/${this.props.place.key}`);
    }
  }

  showFileDialog = () => {
    this.inputOpenFileRef.current.click();
  }

  uploadFile = (e) => {
    // File or Blob named mountains.jpg
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    const extension = file.name.split('.').pop();

    // Create the file metadata
    const metadata = {
      contentType: 'image/jpeg',
    };

    // Create a root reference
    const storageRef = firebase.storage().ref();

    this.setState({
      uploadProgress: 5,
      isUploading: true,
    });

    // Upload file and metadata to the object 'images/mountains.jpg'
    const reader = new FileReader();
    reader.onload = async (event) => {
      const jimp = Jimp.read(reader.result);
      const imgBuffer = await jimp
        .then((img) => {
          img = img.quality(50);
          if (img.bitmap.height > 2048 || img.bitmap.width > 2048) {
            img = img.scaleToFit(2048, 2048);
          }
          return img.getBufferAsync(Jimp.MIME_JPEG);
        })
        .catch((err) => {
          console.error(err);
        });
      const uploadTask = storageRef.child(`placePictures/${this.props.place.key}/${Hapour.generateId(20)}.${extension}`).put(imgBuffer, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${uploadProgress}% done`);
          this.setState({
            uploadProgress,
            isUploading: true,
          });
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running');
              break;
            default:
              break;
          }
        }, (error) => {
          console.error(error);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              break;
          }
        }, () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log(`File available at ${downloadURL}`);
            const prevPictures = this.props.place.placePictures ? this.props.place.placePictures : [];
            const updates = {
              placePictures: [
                ...prevPictures,
                {
                  url: downloadURL,
                  description: '',
                  date: new Date(),
                  tags: [],
                },
              ],
            };
            const placesRef = firebase.database().ref('/places');
            placesRef.child(this.props.place.key).update(
              updates,
            )
              .then(() => { // error handling
                this.setState({
                  editMode: false,
                });
              });
          });
          this.setState({
            uploadProgress: 0,
            isUploading: false,
          });
        });
    };
    reader.readAsDataURL(file);
  }

  gotoPrevImage = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
      imageIndex: this.props.place.placePictures[this.state.currentImage - 1].i,
    });
  }

  gotoNextImage = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
      imageIndex: this.props.place.placePictures[this.state.currentImage + 1].i,
    });
  }

  openLightbox = (i) => {
    this.setState({
      lightboxIsOpen: true,
      currentImage: i,
      imageIndex: this.props.place.placePictures[i].i,
    });
  }

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    });
  }

  deletePhoto = (i) => {
    this.setState({
      lightboxIsOpen: false,
    });
    const placePicturesRef = firebase.database().ref(`/places/${this.props.place.key}/placePictures/${i}/isArchived`);
    placePicturesRef.set(true);
  }

  render() {
    const { classes } = this.props;
    // const lightboxButtons = [
    //   <Button><ThumbUpIcon style={{ color: 'white' }} /></Button>,
    //   <Button><ThumbDownIcon style={{ color: 'white' }} /></Button>,
    //   <Button><NotesIcon style={{ color: 'white' }} /></Button>,
    //   <Button onClick={this.deletePhoto}><DeleteIcon style={{ color: 'white' }} /></Button>,
    // ];
    const { currentImage } = this.state;
    const now = new Date();
    const dowToday = now.getDay();
    const timeToday = now.getHours() * 100 + now.getMinutes();
    const isHhNow = Hapour.isHhNow(this.props.place.hours, { dow: dowToday, hour: timeToday });
    const images = this.props.place.placePictures ? this.props.place.placePictures.map(pic => (pic.url)) : [];

    return (
      <Card style={{ margin: '5px 0px', maxWidth: '100vw', paddingBottom: '4px' }}>
        <CardContent style={{ display: 'flex', alignItems: 'center', paddingTop: '10px', paddingBottom: '4px' }}>
          {/* <div onClick={this.goToPlacePage}>
            <Avatar
              // src={this.props.place.avatarUrl}
              style={{ flex: '0 0 auto', marginRight: '12px' }}
            >
              <RestaurantMenuIcon />
            </Avatar>
          </div> */}
          <div style={{ flex: '1 1 auto' }} onClick={this.goToPlacePage}>
            <Typography variant="body2" component="span" style={{ fontSize: '18px', lineHeight: '1.3em' }}>
              {this.props.place.name || 'Not found :('}
              {isHhNow && <Chip label="🍻hh now" variant="outlined" classes={{ root: classes.chip, label: classes.label }} />}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              color="textSecondary"
              style={{ lineHeight: 'inherit', marginTop: '2px' }}
            >
              { Hapour.friendlyHours(this.props.place.hours) }
              {/* { friendlyHours(this.props.place.hours, { dow: dowToday, hour: timeToday }) } */}
            </Typography>
          </div>
          <IconButton style={{ paddingTop: '0px', paddingRight: '2px', paddingLeft: '2px' }} onClick={this.toggleFavaorite} >
            {this.state.favorited ? (
              <FavoriteIcon />
            ) : (
              <FavoriteBorderIcon />
            )}
          </IconButton>
        </CardContent>
        <CardContent style={{ padding: '4px 16px', cursor: 'default' }} onClick={(this.props.showMap) ? () => {} : this.goToPlacePage}>
          <div style={{ width: '100%', display: 'table' }}>
            <div style={{ display: 'table-row' }}>
              <div style={{ display: 'table-cell' }}>
                <div>
                  {this.props.place.foodNotes && (
                    <div style={{ marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                      {/* <span style={{ fontWeight: '500' }}>Food: </span> */}
                      <RestaurantIcon style={{ fontSize: '14px', color: 'gray', marginRight: '8px' }} />
                      <Typography>{this.props.place.foodNotes}</Typography>
                    </div>
                  )}
                  {this.props.place.drinksNotes && (
                    <div style={{ marginBottom: '4px', display: 'flex', alignItems: 'center'  }}>
                      {/* <span style={{ fontWeight: '500' }}>Drinks: </span> */}
                      <DrinkIcon style={{ fontSize: '14px', color: 'gray', marginRight: '8px' }} />
                      <Typography>{this.props.place.drinksNotes}</Typography>
                    </div>
                  )}
                  {this.props.place.otherNotes && (
                    <div  style={{ marginBottom: '4px', display: 'flex', alignItems: 'center'  }}>
                      <NotesIcon style={{ fontSize: '14px', color: 'gray', marginRight: '8px' }} />
                      <Typography>{this.props.place.otherNotes}</Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.props.place.distance && (
              <div style={{ fontSize: '12px', float: 'right', marginTop: '-6px' }}>
                {(this.props.place.distance * 0.621371).toFixed(2)}
                mi
              </div>
            )}
          </div>
          <div>
            {(this.props.showExternalLinks && (this.props.place.yelpUrl || this.props.place.happyHourUrl)) && (
              <div style={{ textAlign: 'center' }}>
                <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0px', height: '1px', backgroundColor: '#ccc' }} />
                {[{
                  name: 'Maps', url: (this.props.place.address ? ('https://www.google.com/maps/place/' + this.props.place.address) : null)
                }, {
                  name: 'Yelp', url: this.props.place.yelpUrl
                }, {
                  name: 'Happy Hour Site', url: this.props.place.happyHourUrl
                },
                ].map((link) => {
                  if (link.url) {
                    return (
                      <span key={link.url} style={{ color: '#fcfcfc' }}>
                        &nbsp;
                        {' [ '}
                          <a href={link.url} target="_blank" rel="noopener noreferrer" style={{ color: '#333' }}>
                          {link.name}
                        </a>
                        {' ] '}
                        &nbsp;
                      </span>
                    );
                  }
                  return null;
                })}
                <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0px', height: '1px', backgroundColor: '#ccc' }} />
              </div>
            )}
            {this.props.showAddress && this.props.place.address && (
              <div style={{ marginBottom: '6px', display: 'flex', alignItems: 'center'  }}>
                {/* <span style={{ fontWeight: '500' }}>Address: </span> */}
                <PlaceIcon style={{ fontSize: '14px', color: 'gray', marginRight: '8px' }} />
                <Typography>{this.props.place.address}</Typography>
              </div>
            )}
            {this.props.showMap && (
              <GoogleMapView
                containerElement={
                  <div style={{ height: '200px' }} />
                }
                mapElement={
                  <div style={{ height: '200px' }} />
                }
                markers={[
                  {
                    position: {
                      lat: this.props.place.lat,
                      lng: this.props.place.lng,
                    },
                  },
                ]}
                place={{
                  lat: this.props.place.lat,
                  lng: this.props.place.lng,
                }}
              />
            )}
            {(this.props.showPhotos && this.props.place.placePictures && this.props.place.placePictures.length > 0) && (
              <div>
                <hr style={{ marginTop: '5px', marginBottom: '5px', border: '0px', height: '1px', backgroundColor: '#ccc' }} />
                <Typography variant="h6">Photos</Typography>
                <GridList cellHeight={160} cols={3}>
                  {this.state.isUploading && (
                    <GridListTile key="isUploading" cols={1} style={{ textAlign: 'center', marginTop: '50px' }}>
                      <CircularProgress variant="determinate" value={this.state.uploadProgress} />
                    </GridListTile>
                  )}
                  {this.props.place.placePictures.map((pic, i) => (
                    <GridListTile key={pic.url} cols={1} onClick={() => this.openLightbox(i)}>
                      <img src={pic.url} alt={pic.description} />
                    </GridListTile>
                  ))}
                </GridList>
                {this.state.lightboxIsOpen && 
                  <Lightbox
                    mainSrc={images[currentImage]}
                    nextSrc={images[(currentImage + 1) % images.length]}
                    prevSrc={images[(currentImage + images.length - 1) % images.length]}
                    onCloseRequest={this.closeLightbox}
                    onMovePrevRequest={() =>
                      this.setState({
                        currentImage: (currentImage + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        currentImage: (currentImage + 1) % images.length,
                      })
                    }
                  />
                }
                {this.props.place.lastUpdated && (
                  <div style={{ color: 'gray', marginTop: '6px' }}>
                    Last updated:
                    {new Date(this.props.place.lastUpdated).toLocaleDateString()}
                  </div>
                )}
              </div>
            )}
          </div>
        </CardContent>
        {(this.props.addPicsButton || this.props.editButton) && (
          <CardActions style={{ paddingTop: '0px', justifyContent: 'flex-end' }}>
            {(this.props.addPicsButton && this.state.user) && (
              <Button onClick={this.showFileDialog} style={{ color: 'gray' }}>
                <AddPhotosIcon />
              </Button>
            )}
            {this.props.editButton && (
              <Button onClick={this.props.onClickEdit} style={{ color: 'gray' }}>
                <EditIcon />
              </Button>
            )}
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              ref={this.inputOpenFileRef}
              onChange={this.uploadFile}
              style={{ display: 'none' }}
            />
            <div style={{ clear: 'both' }} />
          </CardActions>
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(withRouter(PlaceCard));
