import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import blue from '@material-ui/core/colors/blue';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AppliedRoute from './components/AppliedRoute';
import AboutPage from './containers/AboutPage';
import Base from './components/Base';
import FavoritesPage from './containers/FavoritesPage';
import HelpPage from './containers/HelpPage';
import MapViewPage from './containers/MapViewPage';
import PlacesListPage from './containers/PlacesListPage';
import PlacePage from './containers/PlacePage';
import RecentsPage from './containers/RecentsPage';
import SettingsPage from './containers/SettingsPage';

import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {},
    };
  }

  setBaseState = (k, v) => {
    this.setState({
      [k]: v,
    });
  }

  render() {
    const childProps = {
      setBaseState: this.setBaseState,
      query: this.state.query,
    };
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Base setBaseState={this.setBaseState}>
            <Switch>
              <AppliedRoute exact path="/" component={PlacesListPage} props={childProps} />
              <AppliedRoute exact path="/about" component={AboutPage} props={childProps} />
              <AppliedRoute exact path="/favorites" component={FavoritesPage} props={childProps} />
              <AppliedRoute exact path="/help" component={HelpPage} props={childProps} />
              <AppliedRoute exact path="/nearby" component={MapViewPage} props={childProps} />
              <AppliedRoute exact path="/place" component={PlacePage} props={childProps} />
              <AppliedRoute path="/place/:place_key" component={PlacePage} props={childProps} />
              <AppliedRoute exact path="/recents" component={RecentsPage} props={childProps} />
              <AppliedRoute exact path="/search" component={PlacesListPage} props={childProps} />
              <AppliedRoute exact path="/settings" component={SettingsPage} props={childProps} />
            </Switch>
          </Base>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
