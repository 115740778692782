import React, { Component } from 'react';

import * as firebase from 'firebase';

import Button from '@material-ui/core/Button';

import PlacesList from '../components/PlacesList';

class FavoritesPage extends Component {
  constructor() {
    super();
    this.state = {
      places: [],
      placesRefs: [],
      user: null,
      favsRef: null,
    }
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        user
      })
      if (user) {
        const favsRef = firebase.database().ref(`/users/${user.uid}/favorites/`).limitToFirst(100);
        this.setState({
          favsRef
        });
        this.state.favsRef.on('value', (snapshot) => {
          snapshot.forEach((childSnapshot) => {
            let placeRef = firebase.database().ref(`/places/${childSnapshot.key}`);
            placeRef.on('value', (grandChildSnapshot) => {
              var childKey = grandChildSnapshot.key;
              var childData = grandChildSnapshot.val();
              let place = {
                key: childKey
              };
              place = Object.assign(place, childData);
              this.setState({
                places: this.state.places.concat(place),
                placesRefs: this.state.placesRefs.concat(placeRef),
              })
            })
          });
        });
      }
    });
  }

  componentWillUnmount() {
    if (!!this.state.favsRef) {
      this.state.favsRef.off();
    }
    for (let ref of this.state.placesRefs) {
      ref.off();
    }
  }

  loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }

  render() {
    return (
      (this.state.user ? (
        <PlacesList
          places={this.state.places}
        />
      ) : (
        <div style={{ minHeight: '80vh', fontSize: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <div>
            <Button variant="contained" color="primary" onClick={this.loginWithGoogle}>
              Log in
            </Button>
          </div>
          <div>
            to save and view your
            <br/>
            favorite happy hour spots.
          </div>
        </div>
      ))
    );
  }
}

export default FavoritesPage;
