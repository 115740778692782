import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import * as firebase from 'firebase';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import AboutIcon from '@material-ui/icons/InfoOutlined';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import SearchDialog from './SearchDialog';

const logPageView = () => {
  if (!window.cordova) {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
  } else {
    window.ga.trackView(window.location.pathname);
  }
};

const loginWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
};

const logoutFromGoogle = () => {
  firebase.auth().signOut().then(() => {
    // Sign-out successful.
  }).catch((error) => {
    console.error(error);
  });
};

class Base extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      drawer: false,
      selectedIndex: 0,
      showSearch: false,
      location: '',
    };
  }

  componentWillMount() {
    if (!window.cordova) {
      ReactGA.initialize('UA-102313896-1');
    } else {
      window.ga.startTrackerWithId('UA-102313896-1', 30);
    }

    logPageView();
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        user,
      });
    });

    if (!this.state.user) {
      firebase.auth().getRedirectResult().then((result) => {
        if (result.credential) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const token = result.credential.accessToken;
          // ...
        }
        // The signed-in user info.
        // const user = result.user;
      }).catch((error) => {
        console.error('ERROR', error);
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });
    }

    const bottomNavs = ['/', '/recents', '/favorites', '/nearby'];
    const selectedNav = bottomNavs.indexOf(this.props.location.pathname);
    this.setState({
      selectedIndex: selectedNav,
    });

    // If on initial load, not a supported page, go to Home.
    // Cordova is starting with absolute pathname.
    const supportedPaths = ['place', 'about', 'settings', 'help'];
    if (!bottomNavs.includes(this.props.location.pathname) && !supportedPaths.includes(this.props.location.pathname.split('/')[1])) {
      this.props.history.push('/');
    }

    // Try to get and store location
    this.getLocation();
    setTimeout(this.getLocation, 8000);
    setTimeout(this.getLocation, 16000);
  }

  componentWillUpdate() {
    logPageView();
  }

  componentDidUpdate() {
    const bottomNavs = ['/', '/recents', '/favorites', '/nearby'];
    const selectedNav = bottomNavs.indexOf(this.props.location.pathname);
    if (this.state.selectedIndex !== selectedNav) {
      this.setState({
        selectedIndex: selectedNav,
      });
    }
    // Try to get and store location
    this.getLocation();
  }

  saveGeoPosLocal = (position) => {
    const pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    // let posLocalStorage = JSON.parse(localStorage.getItem('pos'));
    this.setState({
      pos,
    });
    localStorage.setItem('pos', JSON.stringify(pos));
  }

  getLocation = () => {
    navigator.geolocation.getCurrentPosition(this.saveGeoPosLocal);
  }

  select(index) {
    this.setState({ selectedIndex: index });
  }

  handleToggle = () => {
    this.setState({ drawer: !this.state.drawer });
  }

  handleClose = (e) => {
    const drawerLinks = {
      About: '/about',
      'Add a Place': '/place',
      Settings: '/settings',
      'Help & Feedback': '/help',
    };
    this.props.history.push(drawerLinks[e.target.textContent]);
    this.setState({ drawer: false });
  }

  toggleSearch = () => {
    this.setState({
      showSearch: !this.state.showSearch,
    });
  }

  render() {
    return (
      <div className="App" style={{ height: '100vh', backgroundColor: '#fafafa' }}>
        <AppBar
          position="fixed"
        >
          <Toolbar style={{ paddingLeft: '8px', paddingRight: '8px', minHeight: '56px' }}>
            <IconButton onClick={this.handleToggle} color="inherit">
              <MenuIcon />
            </IconButton>
            <Typography type="title" color="inherit" style={{flex: 1, fontWeight: 400, fontSize: '24px' }}>
              <span role="img" aria-label="Hapour - Happy Hour Finder">🍻  Hapour  🍻</span>
            </Typography>
            <IconButton onClick={this.toggleSearch} color="inherit">
              <SearchIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          width={280}
          open={this.state.drawer}
          onClose={() => this.setState({ drawer: false })}
        >
          {this.state.user ? (
            <Card style={{ textAlign: 'left', userSelect: 'none' }}>
              <CardHeader
                title={this.state.user.providerData[0].displayName}
                subheader={this.state.user.providerData[0].email}
                avatar={
                  <Avatar src={this.state.user.providerData[0].photoURL} />
                }
              />
            </Card>
          ) : (
            <div>
              <Card style={{ userSelect: 'none' }}>
                <CardHeader
                  title="Eat, Drink, be Happy"
                  subheader="Not logged in"
                  avatar={<Icon className="material-icons" style={{ fontSize: '32px', marginLeft: '4px', marginTop: '4px' }}>local_drink</Icon>}
                  style={{ paddingRight: '24px' }}
                />
                <CardActions style={{ justifyContent: 'center' }}>
                  <Button onClick={loginWithGoogle}>
                    Log in
                  </Button>
                </CardActions>
              </Card>
            </div>
          )}
          <List style={{ textAlign: 'center' }}>
            <ListItem button onClick={this.handleClose}>
              <ListItemIcon style={{ marginRight: '0px' }}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Add a Place" />
            </ListItem>
            <ListItem button onClick={this.handleClose}>
              <ListItemIcon style={{ marginRight: '0px' }}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={this.handleClose}>
              <ListItemIcon style={{ marginRight: '0px' }}>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Help &amp; Feedback" />
            </ListItem>
            <ListItem button onClick={this.handleClose}>
              <ListItemIcon style={{ marginRight: '0px' }}>
                <AboutIcon />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItem>
            {this.state.user && (
              <ListItem button onClick={logoutFromGoogle}>
              <ListItemIcon style={{ marginRight: '0px' }}>
                <ArrowBackIcon />
              </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            )}
          </List>
        </Drawer>
        {this.props.location.pathname === '/' && (
          <Link to="/place">
            <Button variant="fab" color="primary" style={{ position: 'fixed', bottom: '70px', right: '15px', zIndex: '5' }}>
              <AddIcon />
            </Button>
          </Link>
        )}
        <Paper style={{ position: 'fixed', bottom: '0px', width: '100%', zIndex: '5' }}>
          <BottomNavigation value={this.state.selectedIndex} showLabels>
            <BottomNavigationAction
              label="Home"
              icon={<Icon className="material-icons">home</Icon>}
              onClick={() => {
                this.select(0);
                this.props.history.push('/');
              }}
            />
            <BottomNavigationAction
              label="Recents"
              icon={<Icon className="material-icons">restore</Icon>}
              onClick={() => {
                this.select(1);
                this.props.history.push('/recents');
              }}
            />
            <BottomNavigationAction
              label="Favorites"
              icon={<Icon className="material-icons">favorite</Icon>}
              onClick={() => {
                this.select(2);
                this.props.history.push('/favorites');
              }}
            />
            <BottomNavigationAction
              label="Map"
              icon={<Icon className="material-icons">location_on</Icon>}
              onClick={() => {
                this.select(3);
                this.props.history.push('/nearby');
              }}
            />
          </BottomNavigation>
        </Paper>
        <div className="content" style={{ textAlign: 'left', paddingTop: '56px', paddingBottom: '56px' }}>
          {this.props.children}
        </div>
        <SearchDialog
          open={this.state.showSearch}
          onClose={() => { this.setState({ showSearch: false }); }}
          location={this.state.location}
          currentLocation={this.state.pos}
          history={this.props.history}
          setBaseState={this.props.setBaseState}
        />
      </div>
    );
  }
}

export default withRouter(Base);
