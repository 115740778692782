import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as firebase from 'firebase';
import { GeoFire } from 'geofire';
import {
  withGoogleMap, GoogleMap, InfoWindow, Marker,
} from 'react-google-maps';

import Hapour from '../lib/Hapour';
import dot from '../images/blue_dot_12.png';


// https://stackoverflow.com/questions/7095574/google-maps-api-3-custom-marker-color-for-default-dot-marker/18623391#18623391
// https://css-tricks.com/svg-path-syntax-illustrated-guide/
const pinSymbol = color => ({
  path: 'M 0,0 C -1,-18 -10,-16 -10,-26 A 10,10 0 1,1 10,-26 C 10,-16 1,-18 0,0 z M -2,-26 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0 a 1,1 0 1,1 2,0 1,1 0 1,1 -2,0 M -1 -26 a 1,1 0 1,1 2,0 1,1 0 1,1 -2,0',
  fillColor: color,
  fillOpacity: 1,
  strokeColor: '#000',
  strokeWeight: 1,
  scale: 1,
});

const deg2rad = deg => (deg * (Math.PI / 180));

const getDistanceLatLong = (src, dest) => {
  const lat1 = src[0];
  const lon1 = src[1];
  const lat2 = dest[0];
  const lon2 = dest[1];
  const R = 3959; // radius of earth in mi (6371 km)
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in mi
  return d.toFixed(2);
};

const GoogleMapView = withGoogleMap(props => (
  <GoogleMap
    ref={map => map && map.panTo(props.centerLoc)}
    defaultZoom={14}
    // center={props.centerLoc}
    onClick={props.onMapClick}
    options={{
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      minZoom: 9,
      maxZoom: 18,
    }}
  >
    {props.markers.map((marker, index) => (
      <Marker
        key={index}
        position={marker.position}
        onClick={() => props.onMarkerClick(marker)}
        // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
        options={!marker.isHhNow ? {
          opacity: 0.8,
          zIndex: -25,
        } : {}}
        icon={pinSymbol(marker.isHhNow ? 'red' : '#ddd')}
      >
        {/*
          Show info window only if the 'showInfo' key of the marker is true.
          That is, when the Marker pin has been clicked and 'onCloseClick' has been
          Successfully fired.
        */}
        {marker.showInfo && (
          <InfoWindow onCloseClick={() => props.onMarkerClose(marker)}>
            <div>{marker.infoContent}</div>
          </InfoWindow>
        )}
      </Marker>
    ))}
    {props.userLoc && (
      <Marker
        key="user"
        position={props.userLoc}
        icon={dot}
      />
    )}
  </GoogleMap>
));

class MapViewPage extends Component {
  constructor() {
    super();
    this.state = {
      markers: {},
      user: null,
      userLoc: {
        lat: 47.610378,
        lng: -122.200676,
      },
      queryLoc: {
        lat: 47.610378,
        lng: -122.200676,
      },
      centerLoc: {
        lat: 47.610378,
        lng: -122.200676,
      },
      places: {},
      placesGeo: {},
      placesRef: null,
    };
  }

  componentDidMount() {
    console.log('mount', this.props.query);
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        user,
      });
    });

    const placesRef = firebase.database().ref().child('places');
    this.setState({
      placesRef,
    });
    const placesGeo = firebase.database().ref('/placesGeo/');
    this.geoFire = new GeoFire(placesGeo);
    const pos = JSON.parse(localStorage.getItem('pos'));
    if (pos) {
      this.setState({
        userLoc: pos,
      });
    }
    // if (this.props.query.latlng) {
    //   this.setState({
    //     queryLoc: this.props.query.latlng,
    //   });
    // }
  }

  componentDidUpdate() {
    console.log('update', this.props.query);
    let { lat, lng } = this.state.userLoc;
    if (this.props.query.latlng && this.props.query.latlng.lat && this.props.query.latlng.lng) {
      ({ lat, lng } = this.props.query.latlng);
    }
    if (this.state.queryLoc.lat !== lat
      || this.state.queryLoc.lng !== lng) {
      this.geoQuery();
    }
    if (Object.keys(this.state.places).length !== Object.keys(this.state.placesGeo).length) {
      const now = new Date();
      const dowToday = now.getDay();
      const timeToday = now.getHours() * 100 + now.getMinutes();
      for (const key in this.state.placesGeo) {
        if (key in this.state.places) {
          continue;
        }
        this.setState((state) => {
          state.places[key] = {
            key,
          };
        });
        const placeRef = this.state.placesRef.child(key);
        placeRef.on('value', (snapshot) => {
          const place = {
            distance: this.state.placesGeo[key].distance,
          };
          snapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();
            place[childKey] = childData;
          });
          this.setState((state) => {
            state.places[key] = place;
          });
          const isHhNow = Hapour.isHhNow(place.hours, { dow: dowToday, hour: timeToday })
          const marker = {
            position: {
              lat: place.lat,
              lng: place.lng,
            },
            key: place.key,
            showInfo: false,
            isHhNow,
            infoContent: (
              <Link to={`/place/${encodeURIComponent(place.key)}`}>
                <div>
                  <div style={{ fontWeight: 'bold' }}>
                    {place.name}
                    {isHhNow && ' 🍻'}
                  </div>
                  <hr
                    style={{
                      margin: '3px 0px', height: '1px', border: '0px', backgroundColor: '#ccc'
                    }}
                  />
                  { Hapour.friendlyHours(place.hours) }
                  <br />
                  {`${getDistanceLatLong([this.state.userLoc.lat, this.state.userLoc.lng], [place.lat, place.lng])} mi`}
                </div>
              </Link>
            ),
          };
          this.setState(({ markers }) => {
            markers = Object.assign({}, markers, { [key]: marker });
            return { markers };
          });
        });
      }
    }
  }

  handleMarkerClick = (targetMarker) => {
    this.setState((state) => {
      for (const key in state.markers) {
        state.markers[key]['showInfo'] = (key === targetMarker.key);
      }
      state.centerLoc = targetMarker.position;
      return state;
    });
  }

  handleMarkerClose = (targetMarker) => {
    if (targetMarker.infoContent) {
      console.log('foobar');
      this.setState((state) => {
        state.markers[targetMarker.key].showInfo = false;
        state.centerLoc = targetMarker.position;
        return state;
      });
    }
    // If targetMarker not specified, dismiss all.
    // this.setState(({markers}) => {
    //   const newMarkers = Object.assign({}, markers);
    //   for (const key in newMarkers) {
    //     newMarkers[key].showInfo = false;
    //   }
    //   return ({ newMarkers });
    // });
  }

  geoQuery() {
    let { lat, lng } = this.state.userLoc;
    console.log('geoquery', this.props.query);
    if (this.props.query.latlng && this.props.query.latlng.lat && this.props.query.latlng.lng) {
      ({ lat, lng } = this.props.query.latlng);
      console.log(lat, lng);
    }
    this.setState({
      queryLoc: { lat, lng },
      centerLoc: { lat, lng },
    });
    const geoQueryRef = this.geoFire.query({
      center: [lat, lng],
      radius: 32, // ~ 20mi
    });
    this.setState({
      geoQueryRef,
    });
    geoQueryRef.on('key_entered', (key, location, distance) => {
      const place = {
        key,
        location,
        distance,
      };
      this.setState(({ placesGeo }) => {
        placesGeo = Object.assign({}, placesGeo, { [key]: place });
        return { placesGeo };
      });
    });
  }

  render() {
    const markers = Object.values(this.state.markers);
    return (
      <div style={{ textAlign: 'center' }}>
        <GoogleMapView
          containerElement={
            <div style={{ height: 'calc(100vh - 112px)' }} />
          }
          mapElement={
            <div style={{ height: 'calc(100vh - 112px)' }} />
          }
          onMapLoad={() => {}}
          onMapClick={this.handleMarkerClose}
          markers={markers}
          onMarkerClick={this.handleMarkerClick}
          onMarkerClose={this.handleMarkerClose}
          onMarkerRightClick={() => {}}
          centerLoc={this.state.centerLoc}
          userLoc={this.state.userLoc}
        />
      </div>
    );
  }
}

export default MapViewPage;
