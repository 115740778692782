import React, { Component } from 'react';

import * as firebase from 'firebase';

import Button from '@material-ui/core/Button';

class SettingsPage extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
    }
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({
        user
      })
    });
  }

  loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
  }

  render() {
    return (
      (this.state.user ? (
        <div style={{ minHeight: '80vh', fontSize: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          Oops no settings to configure yet.
        </div>
      ) : (
        <div style={{ minHeight: '80vh', fontSize: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <div>
            <Button variant="contained" color="primary" onClick={this.loginWithGoogle}>
              Log in
            </Button>
          </div>
          <div>
            to configure your account settings.
          </div>
        </div>
      ))
    );
  }
}

export default SettingsPage;
