class Hapour {
  static dec2hex = dec => `0${dec.toString(16)}`.substr(-2);

  static generateId = (len) => {
    const arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, Hapour.dec2hex).join('');
  }

  static friendlyTime = (time) => {
    let [hour, min] = time.split(':');
    const amPm = (hour >= 12) ? 'pm' : 'am';
    hour = Number.parseInt(hour, 10);
    hour = hour >= 12 ? hour - 12 : hour;
    hour = hour === 0 ? 12 : hour;
    min = (min === '00') ? '' : min;
    return `${hour}${min}${amPm}`;
  };

  static isHhNow = (hours, now) => {
    if (!hours || !now) {
      return false;
    }
    // Hack, for now, if now is 0000 - 0200, add 2400 to it.
    const queryTime = (now.hour && now.hour < 200) ? parseInt(now.hour, 10) + 2400 : now.hour;
    let result = false;
    if (now.dow === null && now.hour) {
      hours.forEach((hhTimeRanges) => {
        hhTimeRanges.forEach((timeRange) => {
          const start = parseInt(timeRange.time_start.replace(/:/, ''), 10);
          let end = parseInt(timeRange.time_end.replace(/:/, ''), 10);
          end = (end <= start) ? end + 2400 : end;
          if (queryTime >= start && queryTime <= end) {
            result = true;
          }
        });
      });
      return result;
    }
    const hhTimeRanges = hours[now.dow];
    if (hhTimeRanges && hhTimeRanges.length > 0) {
      if (queryTime === null) {
        return true;
      }
      hhTimeRanges.forEach((timeRange) => {
        const start = parseInt(timeRange.time_start.replace(/:/, ''), 10);
        let end = parseInt(timeRange.time_end.replace(/:/, ''), 10);
        end = (end <= start) ? end + 2400 : end;
        if (queryTime >= start && queryTime <= end) {
          result = true;
        }
      });
    }
    return result;
  };

  static friendlyHours = (hours, now) => {
    const allHours = (now === undefined);
    const results = [];
    const dowLabels = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
    if (!hours) {
      return '';
    }
    if (typeof hours === 'string') {
      return hours;
    }
    const timeRanges = {};
    hours.forEach((dowHours, index) => {
      if (!dowHours) {
        return;
      }
      dowHours.forEach((timeRange) => {
        const timeRangeKey = JSON.stringify(timeRange);
        if (!(timeRangeKey in timeRanges)) {
          timeRanges[timeRangeKey] = [];
        }
        timeRanges[timeRangeKey].push(index);
      });
    });

    for (const timeRangeKey in timeRanges) {
      const dayIndices = timeRanges[timeRangeKey];
      const timeRange = JSON.parse(timeRangeKey);
      const [timeStart, timeEnd] = [Hapour.friendlyTime(timeRange.time_start), Hapour.friendlyTime(timeRange.time_end)];
      let isHhNow = false;
      const nowTime = now && now.hour;
      const nowDow = now && now.dow;
      if (!isHhNow) {
        const start = parseInt(timeRange.time_start.replace(/:/, ''), 10);
        let end = parseInt(timeRange.time_end.replace(/:/, ''), 10);
        end = (end <= start) ? end + 2400 : end;
        if (nowTime >= start && nowTime <= end) {
          isHhNow = true;
        }
      }
      const result = [];
      let runCount = 1;
      let runStart = dayIndices[0];
      let runEnd = -1;
      for (const dayIndex of dayIndices) {
        if (runStart === (dayIndex - runCount)) {
          runCount++;
          runEnd = dayIndex;
        } else {
          if (runCount > 1) {
            let days = `${dowLabels[runStart]}-${dowLabels[runEnd]}`;
            days = (days === 'Sun-Sat') ? 'Daily' : days;
            if (allHours || (isHhNow && dayIndex === nowDow)) {
              result.push(`${days}: ${timeStart}-${timeEnd}`);
            }
            runCount = 1;
            runEnd = -1;  
          } else if (runStart < dayIndex) {
            if (allHours || (isHhNow && dayIndex === nowDow)) {
              result.push(`${dowLabels[runStart]}: ${timeStart}-${timeEnd}`);
            }
          }
          runStart = dayIndex;
        }
      }
      if (runCount > 1) {
        let days = `${dowLabels[runStart]}-${dowLabels[runEnd]}`;
        days = (days === 'Sun-Sat') ? 'Daily' : days;
        if (allHours || (isHhNow && runStart <= nowDow && runEnd >= nowDow)) {
          result.push(`${days}: ${timeStart}-${timeEnd}`);
        }
      } else {
        if (allHours || (isHhNow && runStart === nowDow)) {
          result.push(`${dowLabels[runStart]}: ${timeStart}-${timeEnd}`);
        }
      }
      if (result.length > 0) {
        results.push(result.join(', '));
      }
    };
    return results.join(', ');
  
    // for (const [index, dow] of hours.entries()) {
    //   if (!dow || dow.length === 0) {
    //     continue;
    //   }
    //   const times = dow.map(interval => `${interval.time_start}-${interval.time_end}`);
    //   result.push(`${dowLabels[index]} ${times.join(', ')}`);
    // }
  
    // for (let i = 0; i < hours.length; i++) {
    //   const dow = hours[i];
    //   if (!dow || dow.length === 0) {
    //     continue;
    //   }
      
    //   const times = dow.map(interval => `${interval.time_start}-${interval.time_end}`);
    //   result.push(`${dowLabels[index]} ${times.join(', ')}`);
    // }
  }
}

export default Hapour;
