import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';


import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const config = {
  apiKey: 'AIzaSyD5_jEHXr8gyRa8uIojZGLq_C6HguBk3CY',
  // authDomain: "hapour-2733d.firebaseapp.com",
  authDomain: 'www.hapour.com',
  databaseURL: 'https://hapour-2733d.firebaseio.com',
  projectId: 'hapour-2733d',
  storageBucket: 'hapour-2733d.appspot.com',
  messagingSenderId: '95790795383',
};

const startApp = () => {
  firebase.initializeApp(config);
  ReactDOM.render(<App />, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}
