import React, { Component } from 'react';

class HelpPage extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <div style={{ minHeight: '80vh', fontSize: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <img src="https://68.media.tumblr.com/bf47f2a0ce4d2702ac115641e7692c53/tumblr_ml8nb8UNeT1rw3x6co2_400.gif" alt="Help" />
        <br />
        <a href="mailto:support@hapour.com">support@hapour.com</a>
      </div>
    );
  }
}

export default HelpPage;
