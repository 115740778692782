import React, { Component } from 'react';

class AboutPage extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <div style={{ minHeight: '80vh', fontSize: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <img src="https://images.pexels.com/photos/1268558/pexels-photo-1268558.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" width="100%" alt="" />
      </div>
    );
  }
}

export default AboutPage;
