import React from 'react';
import Fade from '@material-ui/core/Fade';

import PlaceCard from './PlaceCard';
import Hapour from '../lib/Hapour';

const PlacesList = (props) => {
  let { queryString } = props.query || { queryString: '' };
  queryString = queryString.toLowerCase();
  let { queryTime } = props.query || { queryTime: null };
  queryTime = queryTime && queryTime.replace(':', '');
  const { queryDow } = props.query || { queryDow: null };
  let places = Object.keys(props.places).map(key => props.places[key]);
  if (queryString.length > 0) {
    places = places.filter(place => (
      place.name.toLowerCase().includes(queryString)
      || (place.drinksNotes && place.drinksNotes.toLowerCase().includes(queryString))
      || (place.foodNotes && place.foodNotes.toLowerCase().includes(queryString))
      || (place.otherNotes && place.otherNotes.toLowerCase().includes(queryString))
    ));
  }
  if (queryTime || queryDow) {
    places = places.filter(place => (
      Hapour.isHhNow(place.hours, { dow: queryDow, hour: queryTime })
    ));
  }
  places = places.sort((a, b) => a.distance - b.distance)
    .filter(place => typeof place === 'object')
    .map(place => (
      <PlaceCard
        key={place.key}
        place={place}
      />
    ));
  return (
    <div>
      {places.length > 0 ? (
        places
      ) : (
        <Fade in style={{ transitionDelay: '5000ms' }}>
          <div style={{ textAlign: 'center', marginTop: '24px' }}>Sorry, no results</div>
        </Fade>
      )}
    </div>
  );
};

export default PlacesList;
