import React, { Component } from 'react';
import * as firebase from 'firebase';
import { GeoFire } from 'geofire';
import QueryString from 'query-string';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TuneIcon from '@material-ui/icons/Tune';

import PlacesList from '../components/PlacesList';

const styles = theme => ({
  tune: {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
});

class PlacesListPage extends Component {
  constructor() {
    super();
    this.state = {
      userLoc: { lat: 0, lng: 0 },
      queryDow: null,
      queryLoc: { lat: 0, lng: 0 },
      queryString: '',
      queryTime: null,
      resultsLoc: { lat: 0, lng: 0 },
      places: {},
      placesGeo: {},
      placesRef: null,
      filterNow: false,
    };
  }

  componentDidMount() {
    const pos = JSON.parse(localStorage.getItem('pos'));
    const query = QueryString.parse(this.props.location.search);
    const [lat, lng] = (query.l && query.l.split(',').length === 2)
      ? query.l.split(',').map(coord => parseFloat(coord))
      : (pos)
        ? [pos.lat, pos.lng]
        : [47.6101, -122.2015]; // Default to Bellevue for now
    const placesRef = firebase.database().ref().child('places');
    this.setState({
      placesRef,
      queryLoc: { lat, lng },
    });
    console.log(GeoFire);
    this.geoFire = new GeoFire(firebase.database().ref('/placesGeo/'));
  }

  componentDidUpdate() {
    const pos = JSON.parse(localStorage.getItem('pos'));
    const query = QueryString.parse(this.props.location.search);
    const queryString = query.q || '';
    const queryTime = query.t || null;
    const queryDow = query.dow || null;
    if (this.state.queryString !== queryString) {
      this.setState({
        queryString,
      });
    }
    if (this.state.queryTime !== queryTime) {
      this.setState({
        queryTime,
      });
    }
    if (this.state.queryDow !== queryDow) {
      this.setState({
        queryDow,
      });
    }
    const [lat, lng] = (query.l && query.l.split(',').length === 2)
      ? query.l.split(',').map(coord => parseFloat(coord))
      : (pos)
        ? [pos.lat, pos.lng]
        : [47.6101, -122.2015]; // Default to Bellevue for now
    if (lat !== this.state.resultsLoc.lat
      || lng !== this.state.resultsLoc.lng) {
      this.geoQuery({ lat, lng });
    }
    if (Object.keys(this.state.places).length !== Object.keys(this.state.placesGeo).length) {
      Object.keys(this.state.placesGeo).forEach((key) => {
        if (key in this.state.places) {
          return;
        }
        this.setState(({ places }) => {
          places = Object.assign({}, places, { [key]: key });
          return { places };
        });
        const placeRef = this.state.placesRef.child(key);
        placeRef.on('value', (snapshot) => {
          const place = {
            distance: this.state.placesGeo[key].distance,
          };
          snapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();
            place[childKey] = childData;
          });
          this.setState(({ places }) => ({
            places: Object.assign({}, places, { [key]: place }),
          }));
        });
      });
    }
  }

  geoQuery = (latlng, radius = 32) => { // radius ~20 mi
    const { lat, lng } = latlng;
    this.setState({
      resultsLoc: Object.assign({}, latlng),
      places: {},
      placesGeo: {},
      // geoQueryRef,
    });
    const geoQueryRef = this.geoFire.query({
      center: [lat, lng],
      radius,
    });
    geoQueryRef.on('key_entered', (key, location, distance) => {
      const place = {
        key,
        location,
        distance,
      };
      this.setState(({ placesGeo }) => {
        placesGeo = Object.assign({}, placesGeo, { [key]: place });
        return { placesGeo };
      });
    });
  }

  toggleFilterNow = () => {
    const query = QueryString.parse(this.props.location.search);
    const now = new Date();
    const dowNow = now.getDay();
    const timeNow = now.toTimeString().substr(0, 5);
    if (!this.state.filterNow) {
      query.dow = dowNow;
      query.t = timeNow;
      this.props.history.push({
        pathname: '/search',
        search: `?${QueryString.stringify(query)}`,
      });
    } else {
      delete query.dow;
      delete query.t;
      this.props.history.push({
        pathname: '/search',
        search: `?${QueryString.stringify(query)}`,
      });
    }
    this.setState({
      filterNow: !this.state.filterNow,
    });
  }

  resetQuery = (field) => {
    const query = QueryString.parse(this.props.location.search);
    delete query[field];
    this.props.history.push({
      pathname: '/search',
      search: `?${QueryString.stringify(query)}`,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ paddingTop: '5px' }}>
          {/* <Chip
            icon={<TuneIcon />}
            clickable
            color="primary"
            variant="outlined"
            style={{ marginLeft: '5px' }}
            classes={{ label: classes.tune }}
          /> */}
          <Chip
            label="hh now"
            onClick={this.toggleFilterNow}
            clickable
            color="primary"
            variant={this.state.filterNow ? 'default' : 'outlined'}
            style={{ marginLeft: '5px' }}
          />
          {[['queryString', 'q'], ['queryTime', 't'], ['queryDow', 'dow']]
            .filter((field) => {
              if (this.state.filterNow && (field[0] === 'queryTime' || field[0] === 'queryDow')) {
                return false;
              }
              return this.state[field[0]];
            })
            .map((field) => {
              let label = this.state[field[0]];
              if (field[0] === 'queryDow') {
                label = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][label];
              }
              return (
                <Chip
                  label={label}
                  onClick={() => this.resetQuery(field[1])}
                  clickable
                  color="primary"
                  style={{ marginLeft: '5px' }}
                />
              );
            })}
        </div>
        <PlacesList
          places={this.state.places}
          query={{
            queryDow: this.state.queryDow,
            queryLoc: this.state.queryLoc,
            queryString: this.state.queryString,
            queryTime: this.state.queryTime,
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PlacesListPage);
